import React from "react";

function Schedule() {
    return (
        <div className="Schedule">
            <div class="container">
                <br></br>
                <br></br>
                <br></br>
                <iframe title="Schedule" src="https://docs.google.com/spreadsheets/d/e/2PACX-1vQ1uVN08sbdehlCResFRHfqbfecCHFqVHO-A28xKB-7lpHeGTkY7AeMhXMJUR23ARJpFlgYAZboguJB/pubhtml?widget=true&amp;headers=false" height="1300" width="1100">Schedule</iframe>
            </div>
        </div>

    );
}

export default Schedule;