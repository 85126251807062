import React from "react";

function Homework() {
    return (

        <div className="Homework">
            <div class="container">
                <br></br>
                <br></br>
                <br></br>
                <iframe title="Homework" src="https://docs.google.com/spreadsheets/d/e/2PACX-1vTq17u6ieXgO0wv13Jvima-y7sx4_ZauyiJkCHgGDQvK21FiBlmO9Pec8y3zhTCr_H6cIIapzv5Wl_5/pubhtml?widget=true&amp;headers=false" height="1300" width="1100">Homework</iframe>
            </div>
        </div>

    );
}

export default Homework;