import React from "react";

function Resources() {
    return (

        <div className="Resources">
            <div class="container">
                <br></br>
                <br></br>
                <br></br>
                <h3>Integrated Math II Lab Activities:</h3>
                <p>
                    <a href="https://docs.google.com/document/d/1nTjPI3nvmHZ_9KubRss9cGGrERtkxYQqFJ4rhTR21Wk/edit?usp=sharing"><span className="text-color-primary">Visualizing Systems of Equations</span></a>
                </p>

                <p>
                    <a href="https://docs.google.com/document/d/1o3EPbuw8u3AC1e9EB4f2l8iGRN7vlcOOkwaliP5BDsE/edit?usp=sharing"><span className="text-color-primary">Methods For Solving Equations</span></a>
                </p>

                <h3>Guided Activities:</h3>
                <p>
                    <a href="https://docs.google.com/document/d/1ZI67vtKWGGHz2AZDfcWVy-To2vD08kfumRDfJdIt4-4/edit?usp=sharing"><span className="text-color-primary">Activity #1</span></a>
                </p>

                <p>
                    <a href="https://docs.google.com/document/d/1o3EPbuw8u3AC1e9EB4f2l8iGRN7vlcOOkwaliP5BDsE/edit?usp=sharing"><span className="text-color-primary">Methods For Solving Equations</span></a>
                </p>

                <h3>Supplementary Resources for Integrated Math II:</h3>
                <p>
                    <a href="https://www.khanacademy.org/math/math2"><span className="text-color-primary">Khan Academy</span></a>
                </p>
                <p>
                    <a href="https://www.youtube.com/watch?v=9OOrhA2iKak&list=PLDesaqWTN6ESsmwELdrzhcGiRhk5DjwLP&ab_channel=ProfessorLeonard"><span className="text-color-primary">Pre-Calculus (College Level)</span></a>
                </p>
                <p>
                    <a href="https://www.desmos.com/calculator"><span className="text-color-primary">Desmos (Graphing Calculator)</span></a>
                </p>
            </div>
        </div>

    );
}

export default Resources;