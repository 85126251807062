import React, { useState } from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import ButtonGroup from '../elements/ButtonGroup';
import Button from '../elements/Button';
import Image from '../elements/Image';
import Modal from '../elements/Modal';

const propTypes = {
    ...SectionProps.types
}

const defaultProps = {
    ...SectionProps.defaults
}

const Address = ({
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    ...props
}) => {

    const [videoModalActive, setVideomodalactive] = useState(false);

    const openModal = (e) => {
        e.preventDefault();
        setVideomodalactive(true);
    }

    const closeModal = (e) => {
        e.preventDefault();
        setVideomodalactive(false);
    }

    const outerClasses = classNames(
        'address section center-content',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    const innerClasses = classNames(
        'address-inner section-inner',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider'
    );

    return (
        <section
            {...props}
        // className={outerClasses}
        >
            <div className="container-sm">
                <div className={innerClasses}>
                    <div className="address-content">
                        <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200" align="center">
                            To Educators:
                        </h1>
                        <div className="container-xs">
                            <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
                                I am excited to incorporate Data Science, Math, and Web Development to develop educational software to improve the ways students learn in an age where we're surrounded by technology. Given that Amazon, Youtube, Spotify, etc. can recommend exceptional content to their users, why can't we utilize the same technology to improve the academic performance of students by recommending good resources? Although this project is long from being finished, I hope you find the resources on this webpage useful for digitalizing your schoolwork!
                            </p>
                        </div>

                        <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200" align="center">
                            To my Students:
                        </h1>
                        <div className="container-xs">
                            <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
                                It felt like just last week that I started tutoring students at Crawford High School, but I hope you've learned a lot from my experience and guidance through those difficult math problems. Although our time together was rather short, I know every single person I interacted with has potential to achieve great things whether or not it involves education. You all are great!
                            </p>


                        </div>



                    </div>


                </div>
            </div>
        </section>
    );
}

Address.propTypes = propTypes;
Address.defaultProps = defaultProps;

export default Address;